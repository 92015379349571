<template>
  <div class="anchor-navigation">
    <nav>
      <h5>{{ $t('goTo') }}:</h5>
      <a v-if="canViewComments"
         href="#Comments">
        <pills-tag  :text="$tc('comment', 2)"
                    text-color="is-info"
                    :display-empty-pill="true"
                    :total="content.comments.total"/>
      </a>
      <a v-if="canViewRelated"
         href="#Related">
        <pills-tag  :text="$t('related')"
                    text-color="is-info"
                    :display-empty-pill="true"
                    :total="content.related.total"/>
      </a>
      <a v-if="canViewWhereUsed"
         href="#WhereUsed">
        <pills-tag  :text="$t('whereUsed')"
                    text-color="is-info"
                    :display-empty-pill="true"
                    :total="content.whereused.total"/>
      </a>
      <a v-if="canViewSuggestions"
         href="#Suggestions">
        <pills-tag  :text="$tc('suggestion', 2)"
                    text-color="is-info"
                    :display-empty-pill="true"
                    :total="content.suggestions.total"/>
      </a>
    </nav>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from "vue"
import { EntityType } from "@/interfaces/global"

import PillsTag from "@/components/common/PillsTag.vue"

const store = useStore()

const content = computed(() => store.state['content'])
const isPart = computed(() => content.value.type === EntityType.part)
const isPage = computed(() => content.value.type === EntityType.page)
const canViewComments =  computed(() => store.getters['user/canViewComments'])
const canViewRelated = computed(() => store.getters['user/canViewRelated'])
const canViewWhereUsed = computed(() => isPart.value || isPage.value)
const canViewSuggestions = computed(() => {
  return store.getters['user/hasPartOrderSuggestions'] && isPart.value
})
</script>
