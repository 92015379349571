<template>
  <section class="comments-container">
    <div v-if="canAddAndEditComments"
         class="comments-new">
      <comments-edit :comment="editing"
                     @submit="submit"
                     @cancel="cancel" />
      <hr />
    </div>
    <section v-if="comments.length"
             class="comments-results">
      <comments-item v-for="comment in comments"
                     :key="comment.id"
                     :comment="comment"
                     :username="username"
                     :can-add-and-edit-comments="canAddAndEditComments"
                     :can-edit-all-comments="canEditAllComments"
                     @edit="edit"
                     @remove="remove" />
    </section>
    <pagination v-if="comments.length"
                :offset="offset"
                :limit="limit"
                :total="total"
                @changePage="changePage"
                @changePerPage="changePerPage"/>
    <div v-if="!total"
         class="no-data">
      <h1 data-int="comments-no-data"
          class="subtitle has-text-centered">
        {{ $t('assetsNotFound', {type: $tc('comment', 2)}) }}
      </h1>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import CommentsEdit from './CommentsEdit';
import CommentsItem from './CommentsItem';
import Pagination from '@/components/common/Pagination';

export default {
  name: 'Comments',
  components: {
    CommentsEdit,
    CommentsItem,
    Pagination
  },
  data() {
    return {
      editing: {}
    };
  },
  computed: {
    ...mapState({
      contentLoaded: ({ content }) => content.isLoaded,
      username: ({ user }) => user.username,
      comments: ({ content }) => content.comments.items,
      total: ({ content }) => content.comments.total,
      offset: ({ content }) => content.comments.offset,
      limit: ({ content }) => content.comments.limit
    }),
    ...mapGetters({
      canAddAndEditComments: "user/canAddAndEditComments",
      canEditAllComments: "user/canEditAllComments"
    })
  },
  watch: {
    async contentLoaded() {
      await this.getComments();
    },
    comments() {
      this.cancel();
    },
    offset() {
      this.scrollToTop();
    }
  },
  methods: {
    ...mapActions({
      getComments: 'content/getComments',
      saveComment: 'content/saveComment',
      removeComment: 'content/removeComment',
      changePage: 'content/setCommentsPage',
      changePerPage: 'content/setCommentsResultsPerPage'
    }),
    scrollToTop() {
      const results = document.getElementsByClassName('comments-results');
      if (results.length) {
        results[0].scrollTo(0, 0);
      }
    },
    async submit(comment) {
      await this.saveComment({ comment });
    },
    async remove(comment) {
      await this.removeComment({ comment });
    },
    edit(comment) {
      this.editing = JSON.parse(JSON.stringify(comment));
    },
    cancel() {
      this.editing = {
        id: '',
        text: '',
        global: false,
        publicBelowOrg: true
      };
    }
  },
  async created() {
    await this.getComments();
  }
};
</script>

<style scoped>
.comments-container {
  flex: 1;
  display: flex;
  flex-flow: column;
}
hr {
  margin: .5rem 0;
}
.comments-results {
  flex: 1;
  overflow: auto;
  padding: 0 1rem;
  border-bottom: lightgray 1px solid;

}
@media screen and (max-width: 768px), print {
  .comments-results {
    padding: 0 1rem .5rem;
  }
}
.no-data {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
