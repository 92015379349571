import router from '@/router';
import http from '@/http';

import {entityTypePluralize} from './contentHelpers';
import {
  CONTENT_RECEIVED, SUGGESTIONS_RECEIVED, SUGGESTIONS_UPDATE_LIMIT, SUGGESTIONS_UPDATE_OFFSET, CLEAR_SUGGESTIONS
} from './contentTypes';
import { getRoute } from "@/router/getRoute";

const state = getDefaultState();

const actions = {
  async getSuggestions({commit, state, rootState}) {
    try {
      if (rootState.content.isLoaded && !state.isLoaded) {
        if (rootState.user.accessPrivileges.indexOf('PART_ORDER_SUGGESTIONS_ENABLED') >= 0) {
          const entityTypePlural = entityTypePluralize(rootState.content.type);
          const entityId = rootState.content.id;

          const params = {
            limit: state.limit,
            offset: state.offset * state.limit
          };

          const {headers, data} = await http.get(`${entityTypePlural}/${entityId}/suggestions`, {params});
          commit(SUGGESTIONS_RECEIVED, {suggestions: data, meta: headers});
        } else {
          const currentRoute = getRoute();
          const contentPath = currentRoute.path.substring(0, currentRoute.path.indexOf('suggestions'));
          const destPath = rootState.user.accessPrivileges.indexOf('ENABLE_LIBRARY_BETA_FEATURES') !== -1 ? 'details' : 'info'
          router.replace({path: `${contentPath}${destPath}`});
        }
      }
    } catch (err) {
      commit(SUGGESTIONS_RECEIVED, {suggestions: [], meta: { 'x-count': null}});
    }
  },
  setSuggestionsPage({commit, dispatch}, page) {
    commit(SUGGESTIONS_UPDATE_OFFSET, {
      offset: Math.max(page, 0)
    });
    dispatch('getSuggestions');
  },
  setSuggestionsResultsPerPage({commit, dispatch}, limit) {
    commit(SUGGESTIONS_UPDATE_LIMIT, {limit});
    dispatch('getSuggestions');
  }
};

const mutations = {
  [SUGGESTIONS_RECEIVED](state, {suggestions, meta}) {
    state.items = suggestions;
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0;
    state.isLoaded = true
  },
  [CONTENT_RECEIVED](state, {content}) {
    state.items = [];
    state.offset = 0;
    state.limit = 20;
    state.total = content.suggestedCount || 0;
  },
  [SUGGESTIONS_UPDATE_OFFSET](state, {offset}) {
    state.offset = offset;
  },
  [SUGGESTIONS_UPDATE_LIMIT](state, {limit}) {
    state.limit = limit;
    state.offset = 0;
  },
  [CLEAR_SUGGESTIONS] (state) {
    Object.assign(state, getDefaultState())
  }
};

export default {
  state,
  actions,
  mutations
};

function getDefaultState() {
  return {
    items: [],
    offset: 0,
    limit: 20,
    total: 0,
    isLoaded: false
  }
}
