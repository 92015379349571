<template>
  <div class="is-align-items-center is-flex is-flex-direction-row">
    <div v-if="hasPreviewButtons" class="buttons preview-buttons">
      <button class="button"
              :class="{ 'is-primary': selectedPreview === PreviewType.TwoDFile }"
              @click="selectedPreview = PreviewType.TwoDFile">
      <span class="icon">
        <i class="fa-lg fas fa-image" />
      </span>
      </button>
      <button class="button"
              :class="{ 'is-primary': selectedPreview === PreviewType.ThreeDFile }"
              @click="selectedPreview = PreviewType.ThreeDFile">
      <span class="icon">
        <i class="fa-lg fas fa-cube" />
      </span>
      </button>
    </div>
    <div :class="{ 'cursor-pointer': canShowPreview }"
         @click="onOpenPreviewModal">
      <thumbnail v-show="selectedPreview === PreviewType.TwoDFile"
                 class="info-thumbnail"
                 data-int="info-thumbnail"
                 :thumbnail-type="entityType"
                 :thumbnail-url="content.thumbnailUrlLarge" />
      <img v-show="selectedPreview === PreviewType.ThreeDFile"
           class="info-thumbnail"
           :alt="content.partNumber"
           :src="filepathFor3d.png" />
      <div v-if="canShowPreview"
           class="has-text-centered has-text-weight-bold">
        {{ $t('clickToEnlarge') }}
      </div>
    </div>
  </div>
  <two-d-modal v-show="is2dPreviewOpen"
               :filepath="filepathFor2d"
               :key="`${is2dPreviewOpen}`"
               :title="`${content.name} (${content.partNumber})`"
               @close="onClosePreviewModal" />
  <part3d-modal v-if="is3dPreviewOpen"
                :filepath="filepathFor3d.scs"
                :key="`${is3dPreviewOpen}`"
                :title="`${content.name} (${content.partNumber})`"
                @close="onClosePreviewModal" />
</template>

<script setup lang="ts">
import i18n from '@/locales'
import Part3dModal from '@/components/library/content/Part3dModal.vue'
import TwoDModal from '@/components/library/content/TwoDModal.vue'
import { computed, onBeforeMount, Ref, ref } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { Thumbnail } from '@/components/common'
import { getCurrentScsFile } from '@/controllers/library/part3dFile'
import { useComposableForEsc } from '@/components/common/modal/useComposableForEsc'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

enum PreviewType { TwoDFile, ThreeDFile }

const props = defineProps({
  content: {
    type: Object,
    required: true
  },
})
const route = useRoute()
const store = useStore()

const entityType = props.content.contentType ||
  props.content.entityType ||
  props.content.mediaType ||
  props.content.type
const filepathFor2d: Ref<string> = ref('')
const filepathFor3d = ref({
  png: '',
  scs: ''
})

// Details is new beta content info page; Info is the current content info page
const isPartInfo = entityType === 'part' && (['Details', 'Info'].includes(<string>route.name))

const has3dFeatures: boolean = store.getters['user/has3dFeatures'] && !store.state['auth/isWidget']
const has2dModal: boolean = !store.state['auth/isWidget']
const hasPreviewButtons = ref(false)
const is2dPreviewOpen = ref(false)
const is3dPreviewOpen = ref(false)
const selectedPreview = ref(PreviewType.TwoDFile)

const canShowPreview = computed(() => {
  if (!isPartInfo) return false

  switch (selectedPreview.value) {
    case PreviewType.ThreeDFile:
      return !!filepathFor3d.value.scs && has3dFeatures
    case PreviewType.TwoDFile:
      return !!filepathFor2d.value && has2dModal
  }
})
const onClosePreviewModal = () => {
  is2dPreviewOpen.value = false
  is3dPreviewOpen.value = false
}
const onOpenPreviewModal = () => {
  if (!canShowPreview.value) return

  switch (selectedPreview.value) {
    case PreviewType.ThreeDFile:
      if (!filepathFor3d.value.scs) return
      is2dPreviewOpen.value = false
      is3dPreviewOpen.value = true
      break
    case PreviewType.TwoDFile:
      if (!filepathFor2d.value) return
      is2dPreviewOpen.value = true
      is3dPreviewOpen.value = false
      break
  }
}

onBeforeMount(async () => {
  if (!!props.content.thumbnailUrlLarge) {
    filepathFor2d.value = `${window.location.protocol}//${window.location.host}${props.content.thumbnailUrlLarge}`
  }

  if (has3dFeatures) {
    if (isPartInfo) {
      try {
        const part3dFile = await getCurrentScsFile(props.content.id)

        if (!!part3dFile.scsFileUrl) {
          filepathFor3d.value.png = `${window.location.protocol}//${window.location.host}${part3dFile.pngFileUrl}`
          filepathFor3d.value.scs = `${window.location.protocol}//${window.location.host}${part3dFile.scsFileUrl}`
        }
      } catch (e) {
        console.error('3D files failed to load')
        notify({
          title: i18n.global.t('error'),
          text: i18n.global.t('failedToLoad', {
            content: i18n.global.tc('file', 2)
          }),
          type: 'error',
          duration: 5000
        })
      }

    }

    if (!!filepathFor2d.value && !!filepathFor3d.value.scs) {
      selectedPreview.value = PreviewType.TwoDFile
    } else if (!filepathFor2d.value && !!filepathFor3d.value.scs && has3dFeatures) {
      selectedPreview.value = PreviewType.ThreeDFile
    } else {
      selectedPreview.value = PreviewType.TwoDFile
    }
  }

  hasPreviewButtons.value = has3dFeatures &&
    has2dModal &&
    isPartInfo &&
    !!filepathFor2d.value &&
    !!filepathFor3d.value.scs
})

useComposableForEsc({
  dismissFunction: onClosePreviewModal,
  isActive: computed(() => is2dPreviewOpen.value || is3dPreviewOpen.value)
})
</script>

<style scoped lang="scss">
.content-preview-wrapper {
  padding-top: 1em;
}
.info-thumbnail {
  height: 256px;
  margin: 1rem;
  object-fit: contain; // maintain original img ratios
  width: 256px;
}

.preview-buttons {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.75rem 0.75rem 1.75rem 1.75rem !important;

  .button {
    margin-right: unset !important;
  }
}

@media only screen and (max-width: 1120px) {
  .info-thumbnail {
    height: 200px;
    margin: 0;
    width: 200px;
  }
  .preview-buttons {
    margin: 1rem 0.75rem 1rem 1rem !important;
  }
}
@media only screen and (max-width: 768px) {
  .is-align-items-center {
    justify-content: center;
  }
}
img {
  -webkit-user-drag: none;
}
</style>
