// map to component names
// ex. 1: 'InfoWrapper'
export interface ColumnItem {
  [key: number]: string
}
// mapped to left and right column paradigms for layouts
// ex. 'left-column': {}
export interface DefaultTemplate {
  [key: string]: ColumnItem
}
// maps all the media types['part', 'page', 'chapter', 'media'] to their default templates
// ex. 'part': { 'left-column': {1: 'InfoWrapper, 2: 'InfoTags', 3: 'WhereUsed'}, 'right-column': ... }
export interface DefaultTemplates {
  [key: string]: DefaultTemplate
}
export interface TemplateIdentifier {
  type: string,
  id: number
}
export interface DetailPageComponentTitles {
  'InfoWrapper': string,
  'InfoTags': string,
  'WhereUsed': string,
  'Related': string,
  'Comments': string,
  'Suggestions': string
}

const partDefaultTemplate: DefaultTemplate = {
  'leftColumn': {
    1: 'InfoWrapper',
    2: 'InfoTags',
    3: 'WhereUsed'
  },
  'rightColumn': {
    1: 'Related',
    2: 'Comments',
    3: 'Suggestions'
  }
}
const pageDefaultTemplate = {
  'leftColumn': {
    1: 'InfoWrapper',
    2: 'InfoTags',
    3: 'WhereUsed'
  },
  'rightColumn': {
    1: 'Related',
    2: 'Comments'
  }
}
const chapterDefaultTemplate = {
  'leftColumn': {
    1: 'InfoWrapper',
    2: 'InfoTags'
  },
  'rightColumn': {
    1: 'Related',
    2: 'Comments'
  }
}
const mediaDefaultTemplate = {
  'leftColumn': {
    1: 'InfoWrapper',
    2: 'InfoTags'
  },
  'rightColumn': {
    1: 'Related',
    2: 'Comments'
  }
}

export const mediaTemplates: DefaultTemplates = {
  'part': partDefaultTemplate,
  'page': pageDefaultTemplate,
  'chapter': chapterDefaultTemplate,
  'media': mediaDefaultTemplate
}
