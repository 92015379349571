<template>
  <section  v-if="showBanner"
            data-int="hero-image"
            class="hero banner-image is-dark is-hidden-documoto-mobile">
    <div data-int="hero-ribbon" class="hero-body" />
    <button data-int="library-link"
            class="button is-primary search-link"
            v-if="!hideSearchButton"
            @click="isWidget 
              ? widgetNavToSearch('', { 
                clearAllContent: true }) 
              : search()">
      <span class="icon is-medium">
        <i class="fa fa-long-arrow-alt-right"/>
      </span>
      <span>
        {{ buttonLabel }}
      </span>
    </button>
  </section>
</template>

<script>
import { computed } from "vue"
import { mapState, mapActions, useStore } from "vuex"
import { useSearch } from '@/components/widgets/composables/useSearch'

export default {
  name: 'Hero',
  props: {
    buttonLabel: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore()
    const isWidget = computed(() => store.getters['widgets/isWidget'])
    const { navigateToSearch } = useSearch()

    return { isWidget,
      widgetNavToSearch: navigateToSearch }
  },
  computed: {
    ...mapState({
      hideSearchButton: ({ user }) => user.searchHidden
    }),
    showBanner() {
      return !this.$store.getters["user/hasDisabledBanner"]
    }
  },
  methods: {
    ...mapActions({
      navigateToSearch: 'search/navigateToSearch'
    }),
    search() {
      this.navigateToSearch({ input: '' })
    }
  }
};
</script>

<style scoped>
.banner-image {
  flex-shrink: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero {
  flex-shrink: 0;
  box-shadow: 0 -2px 0 gray inset;
}
.hero-body {
  /* padding: 5rem 1.5rem; */
  display: flex;
}
@media only screen and (max-width: 769px) {
  .hero .hero-body {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
}
.search-link {
  flex: 0 0 auto;
  align-self: flex-end;
  margin-bottom: 1rem;
  border-radius: 0;
}

@media only screen and (max-width: 1024px) {
  .hero {
    display: none;
  }
}
</style>
